import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Page from './updateMetaTags';
import DynamicForm from './DynamicForm';
import RotatingLoader from './RotatingLoader';
import { toast } from 'react-toastify';
import { API_CMS_URL, API_KEY, NewsID,BlogID,VlogID, ContactUsPage } from './constants'; // Adjust based on your project structure

const checkPageIdExists = (data, pageId,articleTitle) => {
 // console.log('pageId:'+pageId);
 // console.log("slug"+articleTitle);
 // console.log('data:start');
 // console.log(data);
  if (!data || !data.pageData) {
    return false;
  }
  if (data.publishedArticles && (articleTitle)) {
    return false;
  }

  if (data.oneArticle) {
    if (articleTitle) {
   // console.log('data.oneArticle.searchurl === articleTitle:'+(data.oneArticle.searchurl === articleTitle))
    return data.oneArticle.searchurl === articleTitle;
    }
    else {
      return false;
    }
    //return true;
  }
  
  //console.log('data.pageData.pageId=== pageId:'+(data.pageData.pageId=== pageId));
  return data.pageData.pageId === pageId;
};

const LoadCMSPage = ({ pageId, pageName, initialPageData }) => {
  const { slug } = useParams();
  const [datapage, setDatapage] = useState(initialPageData || null);
  let articleTitle;
  if (slug) {
  if (pageId===NewsID){
    articleTitle=`news-${slug}`;
  } else 
    if (pageId===BlogID){
      articleTitle=`blog-${slug}`;
    } else 
  if (pageId===VlogID){
    articleTitle=`vlog-${slug}`;
  }
  }
  
  const [loading, setLoading] = useState(!checkPageIdExists(initialPageData, pageId,articleTitle));

  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiurl;
        if (pageId===NewsID){
          pageId=-1;
        } else 
          if (pageId===BlogID){
            pageId=-2;
      } else 
      if (pageId===VlogID){
        pageId=-3;
    }
    if (articleTitle){
      apiurl = `${API_CMS_URL}page/${API_KEY}/${articleTitle}`;
    } 
    else 
    if (!isNaN(Number(pageId))) {
      apiurl = `${API_CMS_URL}pagebyid/${API_KEY}/${pageId}`;
 /*     if (pageId>=0){
        }
        else if (pageId === -1) {
          apiurl = `${API_CMS_URL}page/${API_KEY}/news-${slug}`;
        } else if (pageId === -2) {
          apiurl = `${API_CMS_URL}page/${API_KEY}/blogs-${slug}`;
        } else if (pageId === -3) {
          apiurl = `${API_CMS_URL}page/${API_KEY}/vlogs-${slug}`;
        }*/
      } else  {
          apiurl = `${API_CMS_URL}page/${API_KEY}/${pageName}`;
      }

        console.log("sub:"+apiurl);
        if (apiurl) {
          const response = await fetch(apiurl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();

          if (!jsonData.success) {
            toast.error('Data fetch was unsuccessful: ' + (jsonData.message || 'Unknown Error'));
          } else {
            setDatapage(jsonData);
          }
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        toast.error('Failed to fetch data: ' + error.message);
      }
    };

    if (!checkPageIdExists(datapage, pageId,articleTitle)) {
      fetchData();
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [pageId, slug, datapage]);

  if (loading) {
    return <RotatingLoader />;
  }

  const articles = datapage?.publishedArticles || [];
  const article = datapage?.oneArticle || null;

  return (
    <div>
      <Page
        title={datapage?.pageData.title}
        description={datapage?.pageData.metaDescription}
        keywords={datapage?.pageData.metaKeywords}
      />
      <div dangerouslySetInnerHTML={{ __html: datapage?.pageData.pageContent }} />
      {pageName === ContactUsPage && <DynamicForm />}
      {articles.length > 0 && (
        <section className="breadcrumbs py-4">
          <div className="container">
            <ol>
              <li><Link to="/">Home</Link></li>
              <li>News & Events</li>
            </ol>
            <h2 className="title">News & Events</h2>
            <p className="col-lg-10 col-12">All about our progress.</p>
          </div>
        </section>
      )}

      {articles.length > 0 && (
        <div className="container">
          <div className="row mt-5 justify-content-center align-items-center">
            <div className="col-12">
              {articles.map((article, index) => (
                <div key={index}>
                  <Link to={"/"+article.articleurl}><h2 className="pretitle">{article.title}</h2></Link>
                  <p><strong>Author:</strong> {article.author}</p>
                  <p><strong>Published Date:</strong> {new Date(article.publish_date).toLocaleDateString()}</p>
                  <div dangerouslySetInnerHTML={{ __html: article.summary }} />
                  <Link to={"/"+article.articleurl}>Read more</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {article && (
        <div>
          <section className="breadcrumbs py-4">
            <div className="container">
              <ol>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/news">News & Events</Link></li>
                <li>{article.title}</li>
              </ol>
              <h2 className="title">{article.title}</h2>
              <p className="col-lg-10 col-12">{article.title}</p>
            </div>
          </section>
          <div className="container">
            <div className="row mt-5 justify-content-center align-items-center">
              <div className="col-12">
                <h4 className="pretitle">{article.title}</h4>
                <h1 className="title col-12">{article.title}</h1>
                <p><strong>Author:</strong> {article.author}</p>
                <p><strong>Published Date:</strong> {new Date(article.publish_date).toLocaleDateString()}</p>
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadCMSPage;
