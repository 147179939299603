import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faMapMarkerAlt, faHome, faInfoCircle, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import DarkModeToggle from './DarkModeToggle';
import LanguageSelector from './Languages';
//import '../assets/css/style.css';
import '../css/misc.css';
//import logo from '../images/logo.png';
//import logol from '../images/LogoL.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Navbar = ({ menuDisplay, language, languages, onLanguageChange, initialLanguageCode }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModeClass = 'dark-mode';
    if (document.body.classList.contains(darkModeClass)) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
    const observer = new MutationObserver(() => {
      if (document.body.classList.contains(darkModeClass)) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
    });
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    return () => observer.disconnect();
  }, []); 


  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleLinkClick = () => {
    setIsNavCollapsed(true);
    setOpenDropdown(null);
  };

  const handleDropdownClick = (id) => {
    if (openDropdown === id) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(id);
    }
  };

  const filteredMenuDisplay = menuDisplay.filter(menu => menu.hasMenu && menu.languageCode === language);

  const renderSubMenu = (subMenu) => {
    return (
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        {subMenu.map((item) => (
          <li key={item.id}>
            <NavLink className="dropdown-item" to={item.pageName} dangerouslySetInnerHTML={{ __html: item.menuName }} />
          </li>
        ))}
      </ul>
    );
  };

  const getPageName = (pageName) => {
    if (language) {
      return `/${language}${pageName}`;
    }
    return pageName;
  };

  const renderMenuItems = () => {
    return filteredMenuDisplay.map((menuItem) => {
      if (menuItem.subMenu) {
        return (
          <li key={menuItem.id} className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id={`navbarDropdown-${menuItem.id}`}
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded={openDropdown === menuItem.id}
              onClick={() => handleDropdownClick(menuItem.id)}
            >
              <FontAwesomeIcon icon={faUtensils} /> {menuItem.menuName}
            </a>
            {renderSubMenu(menuItem.subMenu)}
          </li>
        );
      } else {
        let icon;
        switch (menuItem.pageName) {
          case getPageName('/'):
            icon = faHome;
            break;
          case getPageName('/contact-us'):
            icon = faMapMarkerAlt;
            break;
          case getPageName('/about'):
            icon = faInfoCircle;
            break;
          default:
            icon = faLayerGroup;
        }
        return (
          <li key={menuItem.id} className="nav-item">
            <NavLink className="nav-link" to={menuItem.pageName} onClick={handleLinkClick}>
              <FontAwesomeIcon icon={icon} /> {menuItem.menuName}
            </NavLink>
          </li>
        );
      }
    });
  };

  return (
    <header>
      <nav className={`navbar navbar-expand-lg ${isDarkMode ? 'navbar-dark-mode' : 'navbar-light bg-light'}`}>
      <NavLink className="navbar-brand" to="/" key='a'>
        <img width={200} alt='logo' src={isDarkMode ? '/assets/images/logoL.png' : '/assets/images/logo.png'} />
      </NavLink>
          <button className="navbar-toggler" type="button" onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavCollapsed ? 'collapse' : 'show'}`}>
            <ul className="navbar-nav mr-auto">
              {renderMenuItems()}
            </ul>
            {languages && (
              <LanguageSelector
                languages={languages}
                onLanguageChange={onLanguageChange}
                initialLanguageCode={initialLanguageCode}
              />
            )}
            <DarkModeToggle />
          </div>

      </nav>
    </header>
  );
};

export default Navbar;
