import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Footer = () => {
  return (
    <footer className="page-foot text-sm-start">
      <section className="bg-gray-darker section-top-55 section-bottom-60">
        <div className="container">
          <div className="row border-start-cell">
            <div className="col-sm-6 col-md-3 col-lg-4">
              <Link className="brand brand-inverse" to="/">
              <img width={200} src='/assets/images/logoL.png' alt="Laroutine Cafe Logo" />

              </Link>
              <ul className="list-unstyled contact-info offset-top-5">
                <li>
                  <div className="unit unit-horizontal unit-top unit-spacing-xxs">
                    <div className="unit-left"><span className="text-white">Address:</span></div>
                    <div className="unit-body text-start text-gray-light">
                      <p>105 Rue de Grenelle, 75007 Paris</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="unit unit-horizontal unit-top unit-spacing-xxs">
                    <div className="unit-left"><span className="text-white">Email:</span></div>
                    <div className="unit-body"><Link className="link-gray-light" to="#">info@laroutinecafe.fr</Link></div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3 offset-top-50 offset-sm-top-0">
              <h4 className="text-uppercase">Our menu</h4>
              <ul className="list-tags offset-top-15">
                <li className="text-gray-light"><Link className="link-gray-light" to="#">burgers</Link></li>
                <li className="text-gray-light"><Link className="link-gray-light" to="#">pizzas</Link></li>
                <li className="text-gray-light"><Link className="link-gray-light" to="#">toasts</Link></li>
                <li className="text-gray-light"><Link className="link-gray-light" to="#">Salads</Link></li>
                <li className="text-gray-light"><Link className="link-gray-light" to="#">drinks</Link></li>
                <li className="text-gray-light"><Link className="link-gray-light" to="#">desserts</Link></li>
              </ul>
            </div>
            <div className="col-sm-10 col-lg-5 offset-top-50 offset-md-top-0 col-md-6">
              <h4 className="text-uppercase">newsletter</h4>
              <div className="offset-top-20">
                <form className="rd-mailform form-subscribe form-inline-flex-xs" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="bat/rd-mailform.php">
                  <div className="form-group">
                    <input className="form-control" placeholder="Your Email" type="email" name="email" data-constraints="@Required @Email" />
                  </div>
                  <button className="btn btn-burnt-sienna btn-shape-circle" type="submit">Subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-20 bg-white">
        <div className="container">
          <div className="row justify-content-xs-center justify-content-sm-between">
            <div className="col-sm-5 offset-top-26 text-md-start">
              <p className="copyright">
                La Routine Cafe
                &nbsp;&#169;&nbsp;<span className="copyright-year"></span>&nbsp;<br className="veil-sm"/><Link to="/privacy">Privacy Policy</Link>
                {/* {%FOOTER_LINK} */}
              </p>
            </div>
            <div className="col-sm-4 offset-top-30 offset-sm-top-0 text-md-end">
              <ul className="list-inline list-inline-sizing-1">
                <li><Link className="link-silver-light" to="https://www.instagram.com/laroutinecafe/"><span className="icon icon-xs fa-instagram"></span></Link></li>
                <li><Link className="link-silver-light" to="#"><span className="icon icon-xs fa-facebook"></span></Link></li>
                <li><Link className="link-silver-light" to="#"><span className="icon icon-xs fa-twitter"></span></Link></li>
                <li><Link className="link-silver-light" to="#"><span className="icon icon-xs fa-google-plus"></span></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
