import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar1'; // Adjust the path as needed

const NavbarWrapper = ({ menuDisplay, languages, language, pageName }) => {
  const [languageCode, setLanguageCode] = useState(language); // Initial state is the provided language
  const navigate = useNavigate();

  const handleLanguageChange = (newLanguage) => {
    if (newLanguage.code !== languageCode) { // Only navigate if the language has changed
      setLanguageCode(newLanguage.code);
      const path = (newLanguage.code && newLanguage.code !== '') ? `/${newLanguage.code}` : `/`;
      //const path = (newLanguage.code && newLanguage.code !== '') ? `/${newLanguage.code}/${pageName}` : `/${pageName}`;
      navigate(path);
    }
  };

  useEffect(() => {
    // Set initial language code only if it's different from the current state
    if (language !== languageCode) {
      setLanguageCode(language || ''); // Default to empty string if language is not set
    }
  }, [language]); // Use language as dependency

  return (
    <div>
      <Navbar
        menuDisplay={menuDisplay}
        languages={languages}
        language={languageCode}
        onLanguageChange={handleLanguageChange}
        initialLanguageCode={languageCode}
      />
    </div>
  );
};
export default NavbarWrapper;
